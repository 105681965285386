import { API_URLS } from "../constants/appConstants";
import API from "./api";

const getSubscriptionPrice = async () => {
  try {
    const response = await API.get(API_URLS.SUBSCRIPTION);
    if (response.status === 201 || response.status === 200) {
      return response.data;
    } else {
      throw new Error(`request failed with status ${response.status}`);
    }
  } catch (err) {
    return err;
  }
};

export const landingService = {
  getSubscriptionPrice,
};
