import './App.scss';
import LandingPage from './pages/LandingPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <BrowserRouter>
        <Routes>
          <Route 
            path={'/'} 
            element={<LandingPage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
