import { Link } from "react-router-dom";
import { landingService } from "../../helpers/landingService";

import landingPageLogo from "../../assets/images/landing_page_logo.png";
import liquidImage from "../../assets/images/liquid.svg";
import liquid2Image from "../../assets/images/liquid_2.svg";
import atomImage from "../../assets/images/atom.svg";
import flowerBudImage from "../../assets/images/flower_bud.svg";
import tenantOverviewBanner from "../../assets/images/tenant_overview_banner.png";
import insightsOverview from "../../assets/images/insights_overview.png";
import "./landingPage.scss";
import { useEffect, useState } from "react";

const LandingPage = () => {
  const [suscriptionPlans, setSuscriptionPlans] = useState({
    id: 0,
    yearly_off_percent: 0,
    monthly_amount: "0",
    yearly_amount: "0",
  });
  const getStartedClick = () => {
    const url = "https://app.happypropy.com";
    window.open(url, "_blank");
  };

  useEffect(() => {
    landingService.getSubscriptionPrice().then((response) => {
      if (response) {
        setSuscriptionPlans(response);
      }
    });
  }, []);

  return (
    <div className="landing-page">
      <div className="header py-4">
        <nav className="navbar navbar-expand-lg bg-transparent navbar-transparent">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={landingPageLogo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav w-100">
                <div className="d-lg-flex align-items-center justify-content-center flex-fill">
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#advantages">
                      Advantages
                    </a>
                  </li>
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#property-insights">
                      Property Insights
                    </a>
                  </li>
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#security">
                      Security
                    </a>
                  </li>
                </div>
                <div className="d-lg-flex align-items-center">
                  <li className="nav-item me-5">
                    <a
                      className="nav-link"
                      href="https://app.happypropy.com"
                      target="blank"
                    >
                      Sign in
                    </a>
                  </li>
                  <li className="nav-item">
                    <button className="btn cta" onClick={getStartedClick}>
                      Get started now
                    </button>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <main className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 one-tool-section text-center">
            <h1>One tool for all your properties</h1>
            <p className="text-big">
              Simplify your property management tasks effortlessly. A single
              solution for streamlined control and enhanced efficiency.
            </p>
            <button className="btn cta" onClick={getStartedClick}>
              Get started now
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="tenant-overview-image text-center">
            <img
              className="img-fluid"
              src={tenantOverviewBanner}
              alt="trnant-overview"
            />
          </div>
        </div>

        <div className="best-solution-section text-center" id="advantages">
          <span className="simple-badge green-badge mb-4">BENEFITS</span>
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2>Best solution for your business</h2>
              <p className="text-big">
                Experience firsthand the advantages of property management.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row benefits-blocks">
                <div className="col-md-6 mb-4 text-start">
                  <div className="benefit-block">
                    <img src={liquidImage} alt="liquid_img" />
                    <h3>Efficient Management</h3>
                    <p>
                      Simplify the intricacies of real estate management,
                      allowing for a dedicated focus on portfolio growth. A
                      seamless experience is ensured through a user-friendly
                      interface.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4 text-start">
                  <div className="benefit-block">
                    <img src={liquid2Image} alt="liquid_img" />
                    <h3>Secure Handling of Data</h3>
                    <p>
                      Rest assured as sensitive data, including banking details,
                      is handled with the highest security standards. Robust
                      encryption measures are employed to safeguard your
                      information.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4 text-start">
                  <div className="benefit-block">
                    <img src={atomImage} alt="liquid_img" />
                    <h3>Effortless Rent Processing</h3>
                    <p>
                      Eliminate the hassle of chasing rent payments. The system
                      enables smooth rent collection with transparent records,
                      streamlining financial management effortlessly.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4 text-start">
                  <div className="benefit-block">
                    <img src={flowerBudImage} alt="liquid_img" />
                    <h3>Comprehensive Property Insights</h3>
                    <p>
                      Gain a thorough understanding of each property within your
                      portfolio. From square footage to amenities, crucial
                      details are readily accessible for effective management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="property-insights-section" id="#property-insights">
          <div className="row m-0 justify-content-center insights-wrapper gap-4">
            <div className="col-12 text-center">
              <span className="simple-badge white-badge">
                PROPERTY INSIGHTS
              </span>
            </div>
            <div className="col-md-10 col-lg-7 ">
              <h2 className="text-white">
                Get more information about your real estate
              </h2>
              <p className="text-big mb-5 text-white text-center">
                Navigate seamlessly through the interface to access not only the
                physical attributes of each property but also the historical
                data that empowers strategic decision-making. Whether evaluating
                investment potential or ensuring optimal living conditions, the
                platform equips you with the insights needed for effective
                property management.
              </p>
              <div className="tenant-overview-image">
                <img
                  src={insightsOverview}
                  className="img-fluid"
                  alt="insight-overview"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="payment-terms-section d-flex justify-content-between">
            <div className="row col-md-10 payment-terms-content-section">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 payment-terms-content">
                <h2 className="text-start mb-4">Transparent payment terms</h2>
                <p>No suprise fees. Cancel anytime</p>

                <div className="my-5 d-flex align-items-end">
                  <div className="paid-yearly d-flex flex-column pe-5">
                    <span className="simple-badge translucent-badge">
                      SAVE {suscriptionPlans.yearly_off_percent}%
                    </span>
                    <h2 className="text-start">
                      ${suscriptionPlans.yearly_amount}
                    </h2>
                    <p>per unit, paid annually</p>
                  </div>
                  <div className="paid-monthly ps-5">
                    <h2 className="text-start">
                      ${suscriptionPlans.monthly_amount}
                    </h2>
                    <p>per unit, paid monthly</p>
                  </div>
                </div>
                <button className="btn cta-white" onClick={getStartedClick}>
                  Get started now
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="footer">
        <div className="py-4 container">
          <nav className="navbar navbar-expand-md bg-transparent navbar-transparent">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src={landingPageLogo} alt="logo" />
              </Link>
              <ul className="navbar-nav w-100">
                <div className="d-md-flex align-items-center justify-content-center flex-fill">
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#advantages">
                      Advantages
                    </a>
                  </li>
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#property-insights">
                      Property Insights
                    </a>
                  </li>
                  <li className="nav-item me-5">
                    <a className="nav-link" href="#security">
                      Security
                    </a>
                  </li>
                </div>
                <div className="d-md-flex align-items-center">
                  <li className="nav-item">
                    <button className="btn cta" onClick={getStartedClick}>
                      Get started now
                    </button>
                  </li>
                </div>
              </ul>
            </div>
          </nav>
          <div className="mt-3 d-md-flex align-items-center justify-content-between">
            <div className="right-reserved-text">
              © 2024. HappyPropy. All rights reserved
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="nav-item me-5">
                <a className="nav-link" href="#/">
                  Terms of Use
                </a>
              </div>
              <div className="nav-item">
                <a className="nav-link" href="#/">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
